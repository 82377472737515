import React from 'react';

import './pop_up_modal_window.css'
import WeatherStationMap from "../WeatherStationMap/WeatherStationMap";
import close_cross from "../../images/icons/close_cross.svg"

const PopUpModalWindow = ({active, setActive, header_text, children}) => {
    return (
        <div className={active ? 'pop-up-modal active' : 'pop-up-modal'} onClick={() => setActive(false)}>
            <div className={active ? 'pop-up-modal__content active' : 'pop-up-modal__content' } onClick={e => e.stopPropagation()}>
                <div>
                    {header_text&&<h3>{header_text}</h3>}
                    {!header_text&&<h3>{" "}</h3>}
                    <img src={close_cross} alt={'close-cross'} width={25} height={25} onClick={() => setActive(false)}/>
                </div>
                {children}
            </div>
        </div>
    );
};

export default PopUpModalWindow;