import React, {useCallback, useEffect, useState} from 'react';
import './add_weather_station.css'
import {addStation} from "../../store";
import { useGeolocation } from "@uidotdev/usehooks";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

const AddWeatherStation = ({setActive}) => {
    const user_location = useGeolocation();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [latLng, setLatLng] = useState({lat: 0, lng: 0});
    const [stationName, setStationName] = useState("")
    const [stationId, setStationId] = useState("")
    const [step, setStep] = useState(1)

    const {user} = useSelector(( state => state.authReducer))

    useEffect(() => {
        if (!user_location.loading) {
            setLatLng({lat: user_location.latitude, lng: user_location.longitude})
        }
    }, [user_location.latitude, user_location.loading, user_location.longitude]);

    const confirmAdding = useCallback(() => {
        setActive(false)

        const  data = {
            accessToken: localStorage.getItem('token'),
            user_id: user.id,
            verificationCode: 1,
            locationName: stationName,
            latitude: latLng.lat,
            longitude: latLng.lng,
            city: "",
            region: "",
            country: ""
        }
        console.log(data)

        dispatch(addStation(data))


            setStationName("")
            setStationId("")
            setLatLng({lat: user_location.latitude, lng: user_location.longitude})
    }, [dispatch, latLng, setActive, stationId, stationName, user.id, user_location.latitude, user_location.longitude]);

    return (
        <div className={'add-weather-station'}>
            <h4>{t("new_station.new")}</h4>
            {step===1&&<StepOne
                stationId={stationId}
                setStationId={setStationId}
                step={step}
                setStep={setStep}
            />}
            {step===2&&<StepTwo
                latLng={latLng}
                setLatLng={setLatLng}
                step={step}
                setStep={setStep}
            />}
            {step===3&&<StepThree
                stationName={stationName}
                setStationName={setStationName}
                step={step}
                confirmAdding={confirmAdding}
            />}
        </div>
    );
};

export default AddWeatherStation;