import React from 'react';
import {useSelector} from "react-redux";
import DropDownMenu from "./DropDownMenu/DropDownMenu";

import "./navigation.css"
import {useTranslation} from "react-i18next";
const Navigation = () => {
    const {user} = useSelector(( state => state.authReducer))
    const { t } = useTranslation();
    return (
        <div className={"navigation"}>
            <div className={"greeting"}>
                <p>{t('greeting')} {user.first_name}</p>
            </div>
            <DropDownMenu/>
        </div>
    );
};

export default Navigation;