import {useState} from "react";

import Button from "../shared/Button";
import "./Autorization.css";
import {useDispatch} from "react-redux";
import {registration} from "../../store";
import AuthTextField from "../shared/AuthTextField/AuthTextField";

export default function SignUp() {

    const [first_name, setFirst_name] = useState()
    const [last_name, setLast_name] = useState()
    const [email, setEmail] = useState()
    const [password, setPassword] = useState()

    const dispatch = useDispatch();

    const handleSubmit = (e) => {
      e.preventDefault();

      dispatch(registration({first_name, last_name, email, password}))

    }

    return (
        <form method="post" onSubmit={handleSubmit}>
            {/*<div className="block">*/}
                <div className="Log-in">
                    <p className="Title">Register</p>

                    <div className={'Log-in__inputs'}>
                        <div style={{gap: 27}}>
                            <AuthTextField text={"Name"} width={'145px'} type="text"
                                           onChange={(e) => setFirst_name(e.target.value)}/>
                            <AuthTextField text={"Surname"} width={'145px'} type="text"
                                           onChange={(e) => setLast_name(e.target.value)}/>
                        </div>
                        <AuthTextField text={"Email"} width={'317px'} type="email"
                                       onChange={(e) => setEmail(e.target.value)}/>
                        <AuthTextField text={"Password"} width={'317px'} type="password"
                                       onChange={(e) => setPassword(e.target.value)}/>
                    </div>
                    <Button type={"submit"} text={"Register"}/>
                </div>
            {/*</div>*/}
        </form>
    );
}
