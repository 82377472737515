"use client";
import React from 'react';

import {
    APIProvider,
    Map,
    AdvancedMarker,
    Pin,
    InfoWindow,
} from "@vis.gl/react-google-maps";

import './weather_station_map.css'

const WeatherStationMap = ({ lat, lng }) => {
    const position = {lat: Number(lat), lng: Number(lng)};

    return (
        <APIProvider apiKey={"AIzaSyAep3s6ZaprSbGU_teBoDHDPmmjAB2wWks"}>
            <div className={"weather-station-map"}>
                <Map defaultZoom={9} center={position} mapId={"aef11bcaed8d296f"} >
                    <AdvancedMarker position={position}>
                        <Pin/>
                    </AdvancedMarker>
                </Map>
            </div>
            </APIProvider>
    );
};

export default WeatherStationMap;