import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import UserService from "../services/UserService";


export const getStations = createAsyncThunk(
    'userSlice/getStations',
    async ({accessToken, user_id})=>{
        try {
            return await UserService.fetchWeatherStations(accessToken, user_id)

        }catch (e){
            console.log(e)
        }
    }
)

export const addStation = createAsyncThunk(
    'userSlice/addStation',
    async (stationData)=>{
        try {
            return await UserService.createWeatherStation(stationData)

        }catch (e){
            console.log(e)
        }
    }
)


const userSlice = createSlice( {
    name:'userSlice',
    initialState: {
        isAuth: false,
        user: {},
        weatherStationsData: [],


        status: null,
        error: null
    },
    reducers:{

    },
    extraReducers: {
        [getStations.pending]: (state, action) =>{
            state.status = 'pending'
            state.error = null
        },
        [getStations.fulfilled]: (state, action) =>{
            state.status = 'fulfilled'
            state.weatherStationsData = action.payload.data

        },
        [getStations.rejected]: (state, action) =>{
            console.log(state)
            console.log(action.payload)
        },


        [addStation.pending]: (state, action) =>{
            state.status = 'pending'
            state.error = null
        },
        [addStation.fulfilled]: (state, action) =>{
            state.status = 'fulfilled'
            console.log(action.payload)


        },
        [addStation.rejected]: (state, action) =>{
            console.log(state)
            console.log(action.payload)
        },
    }
})



const userReducer = userSlice.reducer
// export const {ChangeTerm, ChangePage} = authSlice.actions

export default userReducer