import React from 'react';
import Navigation from "../../Navigation/Navigation";
import "./homepage.css"
import WeatherStations from "../../WeatherStations/WeatherStations";
const Homepage = () => {
    return (
        <div className={"homepage"}>
            <Navigation/>
            <WeatherStations/>
        </div>
    );
};

export default Homepage;