import {useState} from "react";

import SignIp from "../../Auth/Sign-in";
import SignUp from "../../Auth/Sign-up";

import './authentication_page.css'
import LoadingSpinner from "../../shared/LoadingSpinner/LoadingSpinner";
import {useSelector} from "react-redux";
export default function AuthenticationPage() {
    // console.log(checkAuth(true))
    const {status} = useSelector(( state => state.authReducer))
    const handleAuth = () => {

    }

    return (
        <div className={"authentication-page"} onSubmit={() => handleAuth()}>
            {status==='pending'&&<LoadingSpinner/>}
            <div className={'auth'}>
                <SignIp />
                <SignUp />
            </div>

        </div>
    );
}