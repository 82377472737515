import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {getStations} from "../../store";

import WeatherStationItem from "../WeatherStationItem/WeatherStationItem";
import PopUpModalWindow from "../PopUpModalWindow/PopUpModalWindow";
import AddWeatherStation from "../AddWeatherStation/AddWeatherStation";

import white_plus from '../../images/icons/white_plus.png'
import "./weather_stations.css"
const WeatherStations = () => {

    const [modalActive, setModalActive] = useState(false)
    const { t } = useTranslation();

    const {weatherStationsData} = useSelector(( state => state.userReducer))
    const {user} = useSelector(( state => state.authReducer))
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getStations({
            accessToken: localStorage.getItem('token'),
            user_id: user.id
        }))
    }, [dispatch, user.id]);

    return (
        <div className={"weather_stations"}>
            <div className={"table_header"} >
                <h4>{t('active_stations')}</h4>
                <button className={"add_weather_station_button"} onClick={() => setModalActive(true)}>
                    <img src={white_plus} alt={"white_plus"} border="0" width={22} height={22}/>
                    <h4>{t('add_new_station')}</h4>
                </button>
                <PopUpModalWindow active={modalActive} setActive={setModalActive}>
                    <AddWeatherStation setActive={setModalActive}/>
                </PopUpModalWindow>
            </div>

            <div className={"short_line"}/>

            {weatherStationsData.length !==0 && weatherStationsData.map((value, index) => (
                <div key={index} className={'weather-station-block'}>
                    <WeatherStationItem station={value}/>
                    <div className={"long_line"}/>
                </div>

            ))}
        </div>
    );
};

export default WeatherStations;