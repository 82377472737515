import $api from "../http";

export default class UserService {
    static async fetchWeatherStations(accessToken, user_id) {

        return $api.post(`/getAllWeatherStations`,
            {
                user_id: user_id
            })
    }

    static async createWeatherStation(data) {

        return $api.post(`/addNewWeatherStation`, {data})
    }

}