import React from 'react';
import './add_weather_station.css'
import {useTranslation} from "react-i18next";

const StepThree = ({stationName, setStationName, step, confirmAdding}) => {
    const { t } = useTranslation();

    return (
        <div className={'step-three'}>
            <h4 style={{color: 'rgb(205, 205, 205)'}}>{t("new_station.name")}</h4>
            <input
                placeholder={'Введіть назву тут'}

                onChange={e => setStationName(e.target.value)}
            />
            <div className={'next-step'} onClick={() => confirmAdding()}>
                <h4 style={{fontSize: '20px'}}>{t("new_station.done")}</h4>
            </div>
            <div className={'step-info'}>
                <h4 style={{fontSize: '20px'}}>{step}</h4>
                <h3 style={{fontSize: '20px'}}>/3</h3>
            </div>
        </div>
    );
};

export default StepThree;