import {useState} from "react";
import {useDispatch} from "react-redux";

import {login} from "../../store";
import Button from "../shared/Button";
import Checkbox from '@mui/material/Checkbox';
import "./Autorization.css";
import AuthTextField from "../shared/AuthTextField/AuthTextField";

export default function SignIp(){

    const [email, setEmail] = useState()
    const [password, setPassword] = useState()

    const dispatch = useDispatch();


    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(login({email, password}))

    }
    return(
        <form onSubmit={handleSubmit} >
            {/*<div className="block">*/}
                <div className="Log-in">
                    <p className="Title">Log in</p>
                    <div className={'Log-in__inputs'}>
                        <AuthTextField text={"Email"} width={'317px'} type="email" pattern=".+@gmail\.com"
                                       onChange={(e) => setEmail(e.target.value)}/>
                        <AuthTextField text={"Password"} width={'317px'} type="password"
                                       onChange={(e) => setPassword(e.target.value)}/>
                    </div>


                    <div className="forgot-password-btn">
                        <div className="Remember-me-block">
                            <Checkbox
                                defaultChecked
                                sx={{
                                    color: 'rgb(42,42,42)',
                                    '&.Mui-checked': {
                                        color: 'rgb(234, 234, 234)',
                                    },
                                }}
                            />
                            <p>Remember me</p>
                        </div>
                    <p>Forgot password?</p>
                    </div>
                    <Button type={"submit"} text={"Log in"} />
                    <p>Log in with</p>
                </div>
            {/*</div>*/}
      </form>
    )


}