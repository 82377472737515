import React from 'react';
import { MuiOtpInput } from 'mui-one-time-password-input';

import right_arrow from './images/right_arrow.svg'
import './add_weather_station.css';
import {useTranslation} from "react-i18next";

const StepOne = ({stationId, setStationId, step, setStep}) => {
    const { t } = useTranslation();


    const handleConfirm = () => {

        setStep(2)
    }

    return (
        <div className={'step-one'}>
            <h4>{t("new_station.id")}</h4>
            <div className={'myOtp'}>
                <MuiOtpInput
                    value={stationId}
                    length={7}
                    onChange={(value)=> setStationId(value)}
                />
            </div>
            <h5>{t("new_station.description")}</h5>
            <div className={'next-step'} onClick={handleConfirm}>
                <h4 style={{fontSize: '20px'}}>{t("new_station.next")}</h4>
                <img src={right_arrow} alt={'arrow'} width={33} height={18}/>
            </div>
            <div className={'step-info'}>
                <h4 style={{fontSize: '20px'}}>{step}</h4>
                <h3 style={{fontSize: '20px'}}>/3</h3>
            </div>
        </div>
    );
};

export default StepOne;