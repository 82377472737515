import React from 'react';
import {styled, TextField} from "@mui/material";
import './auth_textfiled.css';

const AuthTextField = ({text, width, ...props}) => {
    return (
        <TextField
            {...props}
            className={'customTextField'}
            label={text}
            required
            variant="outlined"
            // id="validation-outlined-input"
            InputLabelProps={{className: "auth-textfield__label"}}
            InputProps={{className: "auth-textfield__input", sx: { borderRadius: '8px' }}}
            sx={{width: width}}
            autoComplete={'off'}
        />
    );
};

export default AuthTextField;