import React from 'react';
import {AdvancedMarker, APIProvider, Map, Pin} from "@vis.gl/react-google-maps";
import right_arrow from "./images/right_arrow.svg";

import './add_weather_station.css';
import {useTranslation} from "react-i18next";


const StepTwo = ({latLng, setLatLng, step, setStep}) => {
    const { t } = useTranslation();

    return (
        <div className={'step-two'}>
            <h4>{t("new_station.location")}</h4>
            <APIProvider
                apiKey={"AIzaSyAep3s6ZaprSbGU_teBoDHDPmmjAB2wWks"}
            >
                <div
                    className={"weather-station-map"}
                    style={{
                        width: '520px',
                        height: '270px',
                        marginBottom: '32px'
                }}

                >
                    <Map
                        mapId={'5550f36edefd00a3'}
                        onClick={
                            ev => {
                                setLatLng(ev.detail.latLng)
                            }
                        }
                        defaultZoom={9}
                        defaultCenter={latLng}
                    >
                        <AdvancedMarker position={latLng}>
                            <Pin/>
                        </AdvancedMarker>
                    </Map>
                </div>
            </APIProvider>
            <div className={'next-step'} onClick={() => setStep(3)}>
                <h4 style={{fontSize: '20px'}}>{t("new_station.next")}</h4>
                <img src={right_arrow} alt={'arrow'} width={33} height={18}/>
            </div>
            <div className={'step-info'}>
                <h4 style={{fontSize: '20px'}}>{step}</h4>
                <h3 style={{fontSize: '20px'}}>/3</h3>
            </div>
        </div>
    );
};

export default StepTwo;