import React, {useState} from 'react';

import green_battery from '../../images/icons/green_battery.png'
import arrow from '../../images/icons/arrow.png'

import "./weather_station_item.css"
import WeatherStationMap from "../WeatherStationMap/WeatherStationMap";
import PopUpModalWindow from "../PopUpModalWindow/PopUpModalWindow";
import {useTranslation} from "react-i18next";


const formatData = (dataString) => {
    return new Date(dataString).toLocaleDateString('en-GB', {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
    }).replace(',', '');
}
const WeatherStationItem = ({station}) => {
    const [modalActive, setModalActive] = useState(false)
    const { t } = useTranslation();

    return (
        <>
            <div className={"weather_stations_item"}>
                <div className={"station_info"}>
                    <p>{station.display_name}</p>
                    <div>
                        <h4>ID: {station.id}</h4>
                        <h5>{t("weather_station.location")}: {`${station?.country}, ${station?.region}, ${station?.city}`}</h5>
                    </div>
                    <button className={"open_map_button"} onClick={() =>setModalActive(true)}>{t("weather_station.view_on_the_map")}</button>
                </div>

                <div className={"battery_info"}>
                    <p>{t("weather_station.battery_charge")}</p>
                    <div className={"battery_item"} style={{backgroundImage: `url(${green_battery})`}}>
                        {/*<img src={green_battery} alt={"green_battery"}/>*/}
                        <p>{station.battery_level}%</p>
                    </div>
                </div>

                <div className={"last_data_info"}>
                    <p>{t("weather_station.latest_received_data")}</p>
                    <div className={"last_data"}>
                        <div className={"section"}>
                            <div>
                                <p>{t("weather_station.data_title.date_and_time")}</p>
                                <p>{formatData(station.timestamp)}</p>
                            </div>
                            <div>
                                <p>{t("weather_station.data_title.temperature")}</p>
                                <p>{station.temperature} °C</p>
                            </div>
                            <div>
                                <p>{t("weather_station.data_title.humidity")}</p>
                                <p>{station.humidity} %</p>
                            </div>
                        </div>
                        <div className={"section"}>
                            <div>
                                <p>{t("weather_station.data_title.wind_speed")}</p>
                                <p>{station.wind_speed} {t("weather_station.data_title.m/s")}</p>
                            </div>
                            <div>
                                <p>{t("weather_station.data_title.wind_direction")}</p>
                                <img style={{transform: `rotate(${station.wind_direction}deg)`}} src={arrow} alt={"arrow"}/>
                            </div>
                            <div>
                                <p>{t("weather_station.data_title.precipitation")}</p>
                                <p>{station.precipitation}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PopUpModalWindow active={modalActive} setActive={setModalActive} header_text={'Шопки Пн.'}>
                <WeatherStationMap lat={station.latitude} lng={station.longitude}/>
            </PopUpModalWindow>
        </>
    );
};

export default WeatherStationItem;