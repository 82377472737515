import React from 'react';
import {useDispatch} from "react-redux";
import {logout} from "../../../store";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';


import 'bootstrap/dist/css/bootstrap.css';
import network_icon from "../../../images/icons/network_icon.svg";
import user_icon from "../../../images/icons/user_icon.svg";
import ukraine_flag from "../../../images/icons/ukraine_flag.svg";
import usa_flag from "../../../images/icons/usa_flag.svg";
import "./dropdown_menu.css"
import {useTranslation} from "react-i18next";

const DropDownMenu = () => {
    const dispatch = useDispatch();

    const { i18n } = useTranslation();

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
    };

    return (
        <div className={"dropdown_menu"}>
            <DropdownButton
                as={ButtonGroup}
                key={"user"}
                id={`dropdown-variants-${"user"}`}
                variant={"variant".toLowerCase()}
                title={<img src={user_icon} alt={"user_icon"} width={35} height={36}/>}
            >
                {/*<Dropdown.Item eventKey="1"><button onClick={() => dispatch(logout())}><h4>logout</h4></button></Dropdown.Item>*/}
                <Dropdown.Item eventKey="1"><button onClick={() => dispatch(logout())}>logout</button></Dropdown.Item>
            </DropdownButton>


            <DropdownButton
                as={ButtonGroup}
                key={"network"}
                id={`dropdown-variants-${"network"}`}
                variant={"variant".toLowerCase()}
                title={<img src={network_icon} alt={"network_icon"} width={39} height={38}/>}
            >
                <Dropdown.Item eventKey="1"><button onClick={() => changeLanguage("ukr")}>
                    <img src={ukraine_flag} alt={"ukraine_flag"} width={31} height={32}/>
                    <h4>Українська</h4>
                </button></Dropdown.Item>
                <Dropdown.Item eventKey="2">
                    <button onClick={() => changeLanguage("en")}>
                        <img src={usa_flag} alt={"usa_flag"} width={31} height={32}/>
                        <h4>English</h4>
                    </button>
                </Dropdown.Item>
            </DropdownButton>
        </div>
    );
};

export default DropDownMenu;