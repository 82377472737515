import './App.css';
import Homepage from "./components/pages/Homepage/Homepage";
import SignIp from "./components/Auth/Sign-in";
import SignUp from "./components/Auth/Sign-up";
import AuthenticationPage from "./components/pages/AuthenticationPage/AuthenticationPage";
import {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {checkAuth} from "./store";


function App() {
    const {isAuth} = useSelector(( state => state.authReducer))

    const dispatch = useDispatch();

    useEffect(() => {
        if (localStorage.getItem('token')) {
            dispatch(checkAuth())
        }
    }, [dispatch])

    if (!isAuth) {
        return (
            <div className={"app"}>
                <AuthenticationPage />
            </div>

        );
    }

    return (
        <div className={"app"}>
            <Homepage/>
        </div>
    );
}

export default App;
